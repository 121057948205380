<template>
  <div class="leftbig">
    <div class="leftTop">高考讲堂</div>
    <div class="menu">
      <el-menu
        style="border-right: 0px"
        :default-active="
          '/' + $route.path.split('/')[1] + '/' + $route.path.split('/')[2]
        "
        @select="handleSelect"
      >
        <el-menu-item index="/lectureHall/lectureHalllecturelist">
          <i class="el-icon-menu"></i>
          <span slot="title">讲座报名</span>
        </el-menu-item>
        <el-menu-item index="/lectureHall/lectureHallvideolist">
          <i class="el-icon-menu"></i>
          <span slot="title">网络课程</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="applet">
      <img :src="QRcode[0]"  :onerror="smallImgError"  alt="小程序" />
    </div>
    <div class="oneToOne" @click="goOneToOne()">
      <img src="../../../assets/img/oneToOne.png" alt="1对1服务" />
    </div>
  </div>
</template>

<script>
import {
  getAdvertisementDetail, //二维码
} from "@/api";
export default {
  data() {
    return {
      QRcode: [],
      active: "1", //激活项
    };
  },
  mounted() {
    // this.handleSelect("1");
    this.getImg();
  },
  methods: {
    goOneToOne(){
      this.$router.push("/volunterservce/oneServe");
    },
    async getImg() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        this.QRcode = res.data.images;
      }
    },

    handleSelect(key, keyPath) {
      console.log("123");
      console.log(key);
      this.$emit("gettype", key);
    },
  },
};
</script>

<style lang="less" scoped>
.oneToOne:hover{
  img{
    transform: scale(1.2);
  }
}
.applet{
  width: 100%;
  img {
        width: 160px;
        margin-left: 50px;
      }
}
/deep/.el-menu-item {
  font-size: 16px !important;
}
/deep/.el-menu-item.is-active {
  color: #1ab394 !important;
}

/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #fff;
  color: #1ab394 !important;
}
.leftbig {
  width: 260px;
  .leftTop {
    background: url(../../../assets/img/leftmune_top_bg.jpg) center top
      no-repeat;
    background-position: 100% auto;
    height: 106px;
    text-align: center;
    line-height: 106px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .menu {
    width: 100%;
  }
}
</style>
