<template>
  <div>
    <!-- 滚动图开始 -->
    <div class="scrollChart">
      <ScrollChart :scrImg="scrImg"></ScrollChart>
    </div>
    <!-- 滚动图结束 -->
    <!-- 内容开始 -->
    <div class="contentbotton">
      <!-- 左侧菜单开始 -->
      <div class="left">
        <!-- <Leftmenu @gettype="gettype"></Leftmenu> -->
        <menuLeft @gettype="gettype" :formdata="formdata"></menuLeft>
      </div>
      <!-- 左侧菜单结束 -->
      <!-- 右侧列表开始 -->
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import {
  getAdvertisementDetail, // 顶部图片
} from "@/api/index.js";
import ScrollChart from "@/components/scrollChart";
import Leftmenu from "./components/leftmenu";
import menuLeft from "@/components/menu";
export default {
  components: {
    ScrollChart,
    Leftmenu,
    menuLeft,
  },
  data() {
    return {
      scrImg: [],
      formdata: {
        topText: "旭德简介",
        menulist: [
          { path: "/lectureHall/lectureHalllecturelist", title: "讲座报名" },
          { path: "/lectureHall/lectureHallvideolist", title: "网络课程" },
        ],
      },
    };
  },
  mounted() {
    this.gettype(this.type);
    this.getTop();
  },
  methods: {
    // 顶部轮播图片
    async getTop() {
      let res = await getAdvertisementDetail({ id: 5 });
      if (res.code == 1) {
        this.scrImg = res.data.images || "";
      }
      function getimgsrc(html) {
        var reg = /<img.+?src=('|")?([^'"]+)('|")?(?:\s+|>)/gim;
        var arr = [];
        let tem = [];
        while ((tem = reg.exec(html))) {
          arr.push(tem[2]);
        }
        console.log(arr);
        return arr;
      }
    },
    gettype(type) {
      if (type == "/lectureHall/lectureHalllecturelist") {
        // 讲座管理
        this.$router.push("/lectureHall/lectureHalllecturelist");
      }
      if (type == "/lectureHall/lectureHallvideolist") {
        this.$router.push("/lectureHall/lectureHallvideolist");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  width: 960px;
  box-sizing: border-box;
  padding-left: 20px;
}
.scrollChart {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.contentbotton {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}
</style>
